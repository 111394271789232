<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Correção de avaliação" class="text-center text-md-start">
      <v-container v-if="fetch">
        <v-row>
          <v-col cols="12" md="6" class="mx-auto">
            <div class="d-flex flex-column align-center flex-md-row comment-row mt-0 pa-3">
              <div class="pa-2">
                <img
                  :src="student.avatar"
                  alt="user"
                  width="50"
                  class="rounded-circle"
                />
              </div>
              <div class="comment-text w-100 pa-3 pb-0">
                <h3 class="font-weight-regular">{{ student.name }}</h3>
                <span class="d-block mb-2 subtitle-2 font-weight-regular">{{ student.email }}</span>
                <p class="body-2 my-0">
                  <v-icon class="body-2 mr-1">mdi-text-box-check-outline</v-icon>Aplicação: {{ application.name }}
                </p>
                <p class="body-2 my-0" v-if="application.started_at">
                  <v-icon class="body-2 mr-1">mdi-alarm</v-icon>Iniciado em {{ format(parseISO(application.started_at), "dd/MM/yyyy 'às' HH:mm:ss") }}
                </p>
                <p class="body-2 my-0" v-if="application.ended_at">
                  <v-icon class="body-2 mr-1">mdi-alarm</v-icon>Finalizado em {{ format(parseISO(application.ended_at), "dd/MM/yyyy 'às' HH:mm:ss") }}
                </p>
                <p class="body-2 my-0" v-if="application.time_spent">
                  <v-icon class="body-2 mr-1">mdi-alarm</v-icon>Tempo gasto: {{ formatSeconds(application.time_spent) }}
                </p>
                <p class="body-2 my-0" v-if="application.access_count">
                  <v-icon class="body-2 mr-1">mdi-monitor-dashboard</v-icon>Quantidade de acessos à avaliação: {{ application.access_count }}
                </p>
                <p class="body-2 my-0">
                  <v-btn
                    @click="sendNote"
                    color="primary"
                    class="mt-4"
                    :disabled="application.status === 'PUBLISHED'"
                  >
                    {{ application.status === 'PUBLISHED' ? 'Nota já devolvida' : 'Devolver nota' }}
                  </v-btn>
                </p>
                <v-btn @click="logs = true" outlined class="mt-4">Visualizar ações do aluno</v-btn>

              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="mb-6 ml-2 ml-md-0 mb-md-0">
            <v-row class="flex flex-column flex-sm-row align-center justify-center tw-w-full">
              <v-col cols="12" sm="6">
                <v-card class="text-center" outlined elevation="0">
                  <v-card-title class="justify-center blue darken-1 white--text py-2 mb-4 ">Nota</v-card-title>
                  <v-card-text style="font-size: 15px">
                    <h1 class="text-primary">Nota final do aluno: {{ application.grade }}</h1>
                    <h1 class="grey--text">Valor total da avaliação: {{ examGrade }} </h1>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-col>
          <DatatablelistResolution :questions="formattedQuestions" @click="openQuestion($event)"></DatatablelistResolution>
        </v-col>
      </v-container>
      <v-container v-else style="overflow: hidden;">
        <v-row>
          <v-col cols="12" md="6" class="mx-auto">
            <div class="d-flex flex-column comment-row mt-0 pa-3">
              <div class="align-center px-3">
                <v-skeleton-loader v-bind="{ boilerplate: true }" type="avatar"></v-skeleton-loader>
              </div>
              <div class="mt-0 pa-0">
                <v-skeleton-loader v-bind="{ boilerplate: true }" class="my-0 py-0" type="list-item-two-line"></v-skeleton-loader>
                <v-skeleton-loader v-bind="{ boilerplate: true }" class="my-0 py-0" type="list-item-three-line"></v-skeleton-loader>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-row class="flex flex-column flex-sm-row align-center justify-center tw-w-full">
              <v-col cols="12" sm="6">
                <v-skeleton-loader v-bind="{ boilerplate: true }" type="image"></v-skeleton-loader>
              </v-col>
              <v-col cols="12" sm="6">
                <v-skeleton-loader v-bind="{ boilerplate: true }" type="image"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0">
            <div class="d-flex d-flex-row flex-wrap">
              <v-col v-for="i in 6" :key="i">
                <v-skeleton-loader v-bind="{ boilerplate: true }" class="ma-0" type="chip"></v-skeleton-loader>
              </v-col>
            </div>
            <v-divider />
            <v-list flat>
              <v-list-item-group>
                <v-list-item
                  v-for="i in 5" :key="i"
                  :link="false"
                  style="border-bottom:1px solid rgb(198 198 198)"
                >
                  <v-list-item-content>
                    <v-skeleton-loader v-bind="{ boilerplate: true }" type="list-item-avatar"></v-skeleton-loader>
                  </v-list-item-content>
                  <v-list-item-action-text><v-skeleton-loader v-bind="{ boilerplate: true }" type="actions"></v-skeleton-loader></v-list-item-action-text>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </base-card>

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Corrigir Questão</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="mt-6">
          <v-row v-if="activeQuestion">
            <v-col cols="12" md="8">
              <p v-html="getQuestionTitle(activeQuestion)" class="my-6" />
              <p v-show="activeQuestion.wordings && activeQuestion.wordings[0].type === 'OPEN_ENDED'" v-html="activeQuestion.wordings[0].wording" class="my-6" />
              <v-list v-if="(activeQuestion.wordings && activeQuestion.wordings[0].type === 'MULTIPLE_CHOICE')">
                <v-list-item v-for="(option) in activeQuestion.wordings[0].options" :key="option.id"
                             :class="{
                               'correct-answer' : option.is_correct,
                               'correct-answer-student' : option.is_correct && option.studentCheck,
                               'incorrect-answer' : !option.is_correct && option.studentCheck
                             }">
                  <v-list-item-icon>
                    <v-icon v-if="option.studentCheck">mdi-radiobox-marked</v-icon>
                    <v-icon v-else>mdi-radiobox-blank</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="d-flex align-center">
                    <v-icon v-if="!option.is_correct && option.studentCheck">mdi-close-thick</v-icon>
                    <v-icon v-if="option.is_correct && option.studentCheck">mdi-check</v-icon>
                    <span v-html=" option.answer"/>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <p
                v-if="activeQuestion.wordings[0].type === 'OPEN_ENDED'
                && activeQuestion.answer.answers && activeQuestion.answer.answers.length"
                class="my-6 tw-bg-gray-200 tw-p-4 tw-rounded-md">
                Resposta:
                {{ activeQuestion.answer.answers[0] }}
              </p>
              <p v-if="activeQuestion.wordings[0].type === 'SLIDER'">
                <v-slider
                 :value="activeQuestion.answer.answers[0]"
                 :max="activeQuestion.wordings[0].params.max"
                 :min="activeQuestion.wordings[0].params.min"
                 thumb-label="always"
                 readonly
                ></v-slider>
              </p>
              <p v-if="activeQuestion.wordings[0].type === 'MATRIX' && activeQuestion.answer.status !== 'NOT_ANSWERED'">
                <matrix-preview :question="activeQuestion" :isResolution="true"/>
              </p>
              <p v-if="activeQuestion.wordings[0].type === 'RATING'">
                <rating-preview
                :value="activeQuestion.answer.answers[0]"
                :length="activeQuestion.wordings[0].params.length"/>
              </p>
              <p v-if="activeQuestion.wordings[0].type === 'MULTIPLE_CHOICE_MULTIPLE_ANSWER'">
                <multiple-choice-multiple-answer
                 :options="activeQuestion.wordings[0].options"
                 :answers="activeQuestion.answer.answers"
                 isResolution="true"
                />
              </p>
              <p class="my-4 font-italic"
                 v-else-if="!activeQuestion.answer.status ||
                 activeQuestion.answer.status === 'NOT_ANSWERED'">O aluno não respondeu a questão</p>
            </v-col>
            <v-col cols="12" md="4" class="px-4">
              <p class="body-2 my-0 font-weight-medium">Status da questão</p>
              <p class="body-2 mb-5">
                {{ getStatusName(activeQuestion.answer.status) }}
              </p>
              <p class="body-2 my-0 font-weight-medium">Tempo gasto na resolução</p>
              <p class="body-2 mb-5" v-if="activeQuestion.answer.length > 0">
                <v-icon class="body-2">mdi-alarm</v-icon>{{ formatSeconds(activeQuestion.answer.time_spent) }}
              </p>
              <v-divider></v-divider>
              <p class="body-2 mb-2 mt-4 font-weight-medium">Correção</p>
              <v-row>
                <v-col sm="3" cols="12">
                  <v-text-field
                    label="Acerto (%)"
                    v-model="percentRaw"
                    background-color="transparent"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col  sm="9" cols="12">
                  <v-slider
                    v-model="percent"
                    thumb-color="blue"
                    step="10"
                    @input="calcPercent"
                    thumb-label="always"
                  ></v-slider>
                </v-col>
                <v-col cols="12" v-if="activeQuestion.answer.notes">
                  <small>Comentário anterior: {{activeQuestion.answer.notes}}</small>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    ref="textArea"
                    label="Comentários"
                    background-color="transparent"
                    v-model="form.notes"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-btn color="primary" elevation="0" @click="sendCorrected(activeQuestion)" class="w-100">Salvar</v-btn>
              <v-btn
                color="primary"
                outlined
                elevation="0"
                class="w-100 mt-2"
                @click="dialog = false"
              >Fechar</v-btn>
            </v-col>
          </v-row>
          <v-row class="tw-h-full">
            <v-col cols="12">
              <div class="text-center mt-4">
                <v-divider></v-divider>
                <v-pagination
                  class=" mt-4"
                  v-model="activeQuestionPagination"
                  :length="Object.keys(questions).length"
                  circle
                ></v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <base-loading />
    </v-dialog>

    <DialogBase title="Ações do aluno" :show="logs" @close="logs=false">
      <resolution-logs v-if="logs" />
    </DialogBase>

  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapMutations } from 'vuex'
import { format, parseISO } from 'date-fns'
import MathJax, { initMathJax, renderByMathjax } from 'mathjax-vue'
import resolutionLogs from './ResolutionLogs'
import { getStatusName } from '@/utils/status'
import DatatablelistResolution from '../../../components/datatable/DatatableResolution.vue'
import DialogBase from '../../../components/dialog/DialogBase.vue'
import { convertHtmlToText } from '@/utils/helpers'
import MatrixPreview from '@/components/wording-preview/MatrixPreview.vue'
import RatingPreview from '@/components/wording-preview/RatingPreview.vue'
import MultipleChoiceMultipleAnswer from '@/components/wording-preview/MutipleChoiceMutipleAnswer.vue'

const options = {
  tex: {
    inlineMath: [['/(', '/)']]
  }
}
function onMathJaxReady () {
  const elements = document.getElementsByClassName('math-tex')
  renderByMathjax(elements)
}
initMathJax(options, onMathJaxReady)
Vue.use(MathJax)
export default {
  components: {
    resolutionLogs,
    DatatablelistResolution,
    DialogBase,
    MatrixPreview,
    RatingPreview,
    MultipleChoiceMultipleAnswer
  },
  data: () => ({
    logs: false,
    dialog: false,
    page: {
      title: 'Correção de Avaliação'
    },
    format,
    parseISO,
    activeQuestionPagination: null,
    id: '',
    percent: 0,
    percentRaw: 0,
    grade: null,
    fetch: false,
    activeQuestion: 0,
    items: [],
    application: {},
    questions: [],
    formattedQuestions: [],
    student: {},
    form: {
      notes: '',
      final_grade: ''
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      }
    ],
    studentAnswerFetch: false,
    examGrade: 0,
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.'
    }
  }),
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.getApplication()
  },
  watch: {
    activeQuestionPagination (val) {
      this.activeQuestion = this.questions[parseInt(val - 1)]
      const finalGrade = this.activeQuestion.answer.final_grade || 0
      this.form = {
        notes: this.activeQuestion.answer.notes,
        final_grade: finalGrade
      }
      this.percent = (finalGrade * 100) / this.activeQuestion.wordings[0].grade
    },
    percentRaw (val) {
      this.percent = val
    },
    dialog (val) {
      if (!val) {
        location.reload()
      }
    }
  },
  methods: {
    convertHtmlToText,
    ...mapMutations(['setLoading']),
    getStatusName,
    getQuestionTitle (question) {
      const wordingOrder = question.answer.wording_order
      const wording = question.wordings[wordingOrder]
      if (wording.type === 'MATRIX') {
        return `${question.main_text}`
      }
      return question.main_text
    },
    formatSeconds (sec) {
      const date = new Date(0)
      date.setSeconds(sec) // specify value for SECONDS here
      return date.toISOString().substr(11, 8)
    },
    sendNote () {
      this.$axios
        .patch(
          `/organizations/${this.id}/exams/${this.$route.params.id}/applications/${this.$route.params.application}/students/${this.$route.params.student}`,
          {
            status: 'PUBLISHED'
          }
        )
        .then(() => {
          this.$store.dispatch('alert', { color: 'success', msg: 'Nota enviada com sucesso' })
          this.application.status = 'PUBLISHED'
          this.dialog = false
        }).finally(() => {
          this.studentsFetch = true
        })
    },
    updateFieldsAfterSave (item) {
      this.questions.forEach((question, index) => {
        if (question.answer.id === item.answer.id) {
          this.questions[index].answer.notes = this.form.notes
          this.questions[index].answer.final_grade = this.form.final_grade
        }
      })
    },
    sendCorrected (item) {
      if (this.$refs.textArea.validate()) {
        this.setLoading(true)
        const payload = {
          final_grade: this.form.final_grade,
          notes: this.form.notes
        }
        this.$axios.patch(
          `/organizations/${this.id}/exams/${this.$route.params.id}/applications/${this.$route.params.application}/students/${this.$route.params.student}/answers/${item.answer.id}`,
          payload
        )
          .then(() => {
            this.$store.dispatch('alert', { msg: 'Correção salva com sucesso!' })
          }).finally(() => {
            this.setLoading(false)
            this.updateFieldsAfterSave(item)
          })
      } else {
        this.$store.dispatch('alert', { color: 'red', msg: 'O campo comentários é obrigatório' })
      }
    },
    getApplication () {
      this.$axios.get(
        `organizations/${this.id}/exams/${this.$route.params.id}/applications/${this.$route.params.application}/students/${this.$route.params.student}/info`
      ).then(res => {
        this.application = {
          ...res.data.data.application_student,
          name: res.data.data.application.name
        }
        this.student = res.data.data.application_student.student
        this.grade = parseFloat(res.data.data.application_student.grade).toFixed(2)
        this.questions = res.data.data.questions
        this.formattedQuestions = this.questions.map(question => {
          return {
            title: this.convertHtmlToText(question.main_text),
            answer: question.answer,
            wordings: question.wordings,
            competency_matriz: {
              code: question.competency_matrices[0] ? question.competency_matrices[0].code : 'Não há código',
              description: question.competency_matrices[0] ? question.competency_matrices[0].description : 'Não há descrição'
            }
          }
        })
        const questionsKeys = Object.keys(res.data.data.questions)
        this.examGrade = questionsKeys.reduce(
          (sum, a) => sum + parseFloat(this.questions[a].wordings[0].grade), this.examGrade
        ).toFixed(2)
        questionsKeys.map(question => {
          const wording = this.questions[question].wordings[0]
          const answers = this.questions[question].answer.answers
          if (wording.options) {
            answers.forEach(value => {
              if (wording.options[value]) {
                wording.options[value].studentCheck = true
              }
            })
          }
        })
        this.mountBreadcrumbs(
          res.data.data.application.exam.name,
          res.data.data.application.exam.id,
          res.data.data.application.name,
          res.data.data.application.id
        )
      }).finally(() => {
        this.setLoading(false)
        this.studentAnswerFetch = true
        this.fetch = true
      })
    },
    openQuestion (index) {
      this.activeQuestion = this.questions[index]
      this.activeQuestionPagination = parseInt(index + 1)
      const finalGrade = this.activeQuestion.answer.final_grade || 0
      this.form = {
        notes: this.activeQuestion.answer.notes,
        final_grade: finalGrade
      }
      this.percent = (finalGrade * 100) / this.activeQuestion.wordings[0].grade
      this.dialog = true
    },
    calcPercent () {
      this.form.final_grade = (this.percent * this.activeQuestion.wordings[0].grade) / 100
    },
    mountBreadcrumbs (examName, examId, applicationName, applicationId) {
      this.breadcrumbs = []
      this.breadcrumbs.push(
        {
          text: examName,
          disabled: false,
          to: `/exam/view/${applicationName}`
        },
        {
          text: applicationName,
          disabled: false,
          to: `/exam/application/view/${examId}/${applicationId}`
        },
        {
          text: 'Resolução dos Alunos',
          disabled: false,
          to: `/exam/application/students/${examId}/${applicationId}`
        },
        {
          text: `Correção da Avaliação de ${this.student.name.split(' ')[0] || 'aluno'}`,
          disabled: true
        }
      )
    }
  }
}
</script>
<style>
#question .v-list-item  p {
  margin: 0;
}

</style>
<style scoped>
.incorrect-answer {
  background-color: #ef9a9a;
}
.correct-answer {
  background-color: #7dbce6;
}
.correct-answer-student {
  background-color: #81c784;
}
.corrected {
  background-color: #ececec;
}
</style>
