<template>
    <v-card-text v-intersect="onIntersect" class="py-0">
      <v-timeline
        align-top
        dense
        v-for="(item) in logs"
        :key="item.metadata.id"
      >
        <v-timeline-item
          :color="formatColorTitle(item.data.type)"
          :icon="formatIconTitle(item.data.type)"
            fill-dot
        >
          <v-row class="pt-1">
            <v-col cols="12" md="3">
              <span class="tw-italic">{{  format(parseISO(item.data.when), "dd/MM/yyyy 'às' HH:mm:ss") }}</span>
            </v-col>
            <v-col>
              <strong>{{ formatTitle(item.data.type) }}</strong>
              <div class="text-caption" v-if="item.data.type==='INPUT_TYPE'">
                <div v-for="(i,index) in toJSON(item.data.value)" :key="index">
                    <v-chip > {{ i }} </v-chip>
                </div>
              </div>
              <div class="text-caption" v-if="item.data.type==='EXAM_APPLICATION_OFFLINE'">
                  <div>
                    Desconexão: {{  format(parseISO(item.data.when), "dd/MM/yyyy 'às' HH:mm:ss") }}
                  </div>
                  <div>
                    Conexão: {{  format(parseISO(item.data.until), "dd/MM/yyyy 'às' HH:mm:ss") }}
                  </div>
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
</template>

<script>
import { format, parseISO } from 'date-fns'

export default {
  data () {
    return {
      logs: [],
      id: '',
      page: 1
    }
  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.getLogs()
  },
  methods: {
    format,
    parseISO,
    onIntersect () {
      this.page += 1
      this.getLogs()
    },
    formatTitle (title) {
      switch (title) {
        case 'EXAM_APPLICATION_START':
          return 'Aluno iniciou a resolução'
        case 'EXAM_APPLICATION_FINISH':
          return 'Aluno finalizou a avaliação'
        case 'EXAM_APPLICATION_GET_OUT':
          return 'Aluno deixou a resolução da avaliação'
        case 'EXAM_APPLICATION_COME_BACK':
          return 'Aluno voltou a resolução da avaliação'
        case 'EXAM_APPLICATION_OFFLINE':
          return 'Aluno perdeu a conexão'
        case 'QUESTION_DISPLAY':
          return 'Aluno está lendo a questão'
        case 'WORDING_DISPLAY':
          return 'Aluno está lendo o enunciado'
        case 'ANSWER_CHOOSE':
          return 'Aluno escolheu uma alternativa'
        case 'INPUT_TYPE':
          return 'Aluno digitou as teclas'
      }
    },
    formatColorTitle (title) {
      switch (title) {
        case 'EXAM_APPLICATION_START':
          return 'cyan accent-4'
        case 'EXAM_APPLICATION_FINISH':
          return 'green'
        case 'EXAM_APPLICATION_GET_OUT':
          return 'orange'
        case 'EXAM_APPLICATION_COME_BACK':
          return 'light-green accent-3'
        case 'EXAM_APPLICATION_OFFLINE':
          return 'grey darken-2'
        case 'QUESTION_DISPLAY':
          return 'blue lighten-2'
        case 'WORDING_DISPLAY':
          return 'blue lighten-1'
        case 'ANSWER_CHOOSE':
          return 'lime darken-2'
        case 'INPUT_TYPE':
          return 'deep-orange darken-3'
      }
    },
    formatIconTitle (title) {
      switch (title) {
        case 'EXAM_APPLICATION_START':
          return 'mdi-text-box-outline'
        case 'EXAM_APPLICATION_FINISH':
          return 'mdi-text-box-check-outline'
        case 'EXAM_APPLICATION_GET_OUT':
          return 'mdi-logout'
        case 'EXAM_APPLICATION_COME_BACK':
          return 'mdi-login'
        case 'EXAM_APPLICATION_OFFLINE':
          return 'mdi-wifi-remove'
        case 'QUESTION_DISPLAY':
          return 'mdi-text'
        case 'WORDING_DISPLAY':
          return 'mdi-text'
        case 'ANSWER_CHOOSE':
          return 'mdi-checkbox-outline'
        case 'INPUT_TYPE':
          return 'mdi-keyboard-outline'
      }
    },
    toJSON (strirng) {
      return JSON.parse(strirng)
    },
    async getLogs () {
      try {
        const classResponse = await this.$axios.get(`organizations/${this.id}/exams/${this.$route.params.id}/applications/${this.$route.params.application}/students/${this.$route.params.student}/logs?page=${this.page}`)
        if (!classResponse.data) throw classResponse
        classResponse.data.items.map(item => (this.logs.push(item)))
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
